import BaseBean from "@/utils/BaseBean";

export interface ICxCardDataObj {
    utilInst:CxCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class CxCardUtil extends BaseBean{
    public dataObj:ICxCardDataObj;

    constructor(proxy:any,dataObj:ICxCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.toolBrandData=await this.utils.ToolsProviderApi.getAllToolBrand({flag:0});
    }
}